import { render, staticRenderFns } from "./GetAddressesList.vue?vue&type=template&id=43dcceb9&scoped=true"
import script from "./GetAddressesList.vue?vue&type=script&lang=js"
export * from "./GetAddressesList.vue?vue&type=script&lang=js"
import style0 from "./GetAddressesList.vue?vue&type=style&index=0&id=43dcceb9&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43dcceb9",
  null
  
)

export default component.exports