<template>
  <div>
    <el-card class="intanle" v-if="!istanchuang">
      <span class="h1">配送先住所管理</span>
    </el-card>
    <el-card class="ft-14">
      <el-row>
        <el-col>
          <span>受取人名:</span>
          <el-input v-model="consigneeName" placeholder="受取人名入力" style="width: 19%"></el-input>
          <span style="margin-left: 15px">受取人連絡先：</span>
          <el-input v-model="consigneeTel" placeholder="受取人連絡先" style="width: 19%"></el-input>
          <span style="float: right; z-index: 5; position: relative">
            <el-button style="margin-left: 20px;border: none" type="primary" @click="searchList">検索 </el-button>
            <el-button type="info" style="border: none" @click="resetList">リセット</el-button>
          </span>
          <p v-if="!istanchuang">
            <span style="float: left">
              <el-button @click="toinsert" type="primary" icon="el-icon-plus" style="background-color: #2a4b9b; border: none">新規</el-button>

              <!-- <el-button type="primary" icon="el-icon-delete" @click="handleDelete1()" style=" background-color: rgb(215 163 16);
                  border: none;
                  margin-left: 15px;
                ">削除</el-button> -->
            </span>
          </p>
        </el-col>
      </el-row>
    </el-card>
    <!-- リストカードエリア -->
    <el-card>
      <el-table
        max-height="550px"
        @sort-change="sortChange"
        ref="multipleTable"
        @current-change="selectThisLine"
        v-loading="loading"
        :data="addresslist"
        :highlight-current-row="istanchuang"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        border
        stripe
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column label="ユーザー名" prop="userName" v-if="!istanchuang" width="150">
          <template slot-scope="scope">
            <el-button @click="openDetails(scope.row)" type="text" size="small" style="color: #409EFF; text-decoration: underline">{{ scope.row.userName }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="ユーザー名" prop="userName" v-else width="150"></el-table-column>
        <el-table-column label="受取人氏名" prop="consigneeName" width="150"></el-table-column>
        <el-table-column label="受取人連絡先" prop="consigneeTel" width="250"></el-table-column>
        <el-table-column label="受取人住所" prop="consigneeAddress"></el-table-column>
        <el-table-column label="デフォルト確認" prop="isDefault" width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.isDefault == 0">いいえ</span>
            <span v-else>はい</span>
          </template>
        </el-table-column>
        <el-table-column label="作成日時" prop="createTime" width="200" sortable="custom"></el-table-column>
        <el-table-column label="操作" width="200" v-if="!istanchuang">
          <template slot-scope="scope">
            <el-button @click.native.stop="handleupdate(scope.$index, scope.row)" type="text" size="small" class="el-icon-edit" style="color: #1890ff">編集</el-button>
            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="small" class="el-icon-delete" style="color: #da0909">削除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- ページング -->
      <el-pagination v-if="total > 10" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
      <div style="clear:both;"></div>
    </el-card>
  </div>
</template>

<script>
import addressService from '@/api/address/addressService'

export default {
  // 父级传的参数
  props: {
    istanchuang: {
      type: Boolean,
      default: false
    },
    user: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      addressService: new addressService(), //インターフェース定義
      consigneeName: '', //受取人住所
      consigneeTel: '', //受取人電話
      currentPage: 1, //ページ数
      pageSize: 10, //1ページあたりの件数
      total: 0, //合計数
      addresslist: [], //配送先住所リストデータ
      loading: true, //loading
      addressId: '',  //住所ID
      sortBy: '',  //ソート
      multipleSelection: [], //複数選択データ
      item: [] //親子間の通信パラメータ
    }
  },
  created() {
    //更新からの戻りかどうかを判断する / 詳細を返す
    if (localStorage.getItem('updateState')) {
      var zph_management_params = JSON.parse(localStorage.getItem('zph_management_params'))
      this.consigneeName = zph_management_params.consigneeName //受取人住所
      this.consigneeTel = zph_management_params.consigneeTel //受取人電話番号
      this.currentPage = Number(zph_management_params.currentPage) //ページ数
      this.pageSize = Number(zph_management_params.pageSize) //1ページあたりの件数
      localStorage.removeItem('updateState')
      localStorage.removeItem('zph_management_params')
    }
    //リスト取得
    this.GetAddressList()
  },
  methods: {
    // 上位のポップアップデータ
    selectThisLine(row) {
      this.item = row
      this.$emit('submitForm')
      // return item
    },
    // 传给父级
    passFather() {
      return this.item //this.ruleForm下位コンポーネントデータ
    },
    // 複数ボックスの値取得
    handleSelectionChange(val) {
      if (val.length != 0) {
        this.multipleSelection = val
        this.addressId = val[0].addressId
      }
    },
    /* ページサイズ変更のイベント監視 */
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.GetAddressList()
    },
    /* ページ番号値変更のイベント監視 */
    handleCurrentChange(newPage) {
      this.currentPage = newPage
      this.GetAddressList()
    },
    //リスト取得
    async GetAddressList() {
      const date = {
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        currentPage: this.currentPage, //ページ数
        pageSize: this.pageSize //1ページあたりの件数
      }
      this.addressService.list(date).then(res => {
        if (res.data.success == 'true') {
          this.addresslist = res.data.result
          this.loading = false
          this.total = Number(res.data.maxRecords)
          for (var i = 0; i < this.addresslist.length; i++) {
            if (this.addresslist[i].enabled == 1) {
              this.addresslist[i].enabled = true
            } else {
              this.addresslist[i].enabled = false
            }
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    //照会
    async searchList() {
      this.currentPage = 1
      this.GetAddressList()
    },
    //詳細ページ遷移
    openDetails(row) {
      this.local()
      this.$router.push({
        name: 'GetAddressesDetails',
        query: {
          addressId: row.addressId,
          type: 'details'
        }
      })
    },
    //変更ページ移動
    handleupdate(index, row) {
      this.local()
      this.$router.push({
        name: 'GetAddressesDetails',
        query: {
          addressId: row.addressId,
          type: 'update',
          currentPage: this.currentPage
        }
      })
    },
    //新規追加ページ遷移
    toinsert() {
      this.local()
      this.$router.push({
        name: 'GetAddressesDetails',
        query: {
          type: 'insert'
        }
      })
    },
    //リセット
    resetList() {
      // this.name = ""
      this.consigneeName = ''
      this.consigneeTel = ''
      this.currentPage = 1
      this.GetAddressList()
    },
    //1行削除
    handleDelete(row) {
      // console.log(row)
      var data = {
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        ...row
      }
      this.$confirm('この操作はデータを永久に削除します。続行しますか?', '確認', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.post(this.$url.DeleteAddress, 
            data
          )
          // .then((res) => {
          this.loading = false
          if (res.success == 'true') {
            this.$message({
              type: 'success',
              message: '削除成功!'
            })
            this.GetAddressList()
          } else {
            this.$message({
              type: 'error',
              message: res.message
            })
          }
          // })
        })
        .catch(err => {
          this.GetAddressList()
        })
    },
    //リストのソート
    sortChange(column) {
      if (column.order == 'ascending') {
        if (column.prop == 'createTime') {
          this.sortBy = 'a.createTime ASC'
        }
      } else if (column.order == 'descending') {
        if (column.prop == 'createTime') {
          this.sortBy = 'a.createTime DESC'
        }
      }
      this.GetAddressList()
    },
    //編集時にフィルタ条件をキャッシュに保存
    local() {
      var zph_management_params = {
        consigneeName: this.consigneeName, //配送先名
        consigneeTel: this.consigneeTel, //配送先電話番号
        currentPage: this.currentPage, //ページ数
        pageSize: this.pageSize //1ページあたりの件数
      }
      localStorage.setItem('zph_management_params', JSON.stringify(zph_management_params))
    }
  }
}
</script>

<style lang="less" scoped>
.cl-hui {
  color: #b2b2b2 !important;
}

.cl-bl {
  color: #606262;
}
</style>
